import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { generateDate } from '../helpers/commonFunctions';
import Seo from '../components/seo';
import Layout from '../components/layout';
import amNewsHero from '../images/am-news-sm.jpg';
import HtmlParser from '../helpers/htmlParser';
import { GatsbyImage } from 'gatsby-plugin-image';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import moment from 'moment';
import { EventPromo } from '../components/eventPromo';

const Singlenews = ({ data, pageContext, location }) => {
    function capitalize(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    const pageRawTitle = location.pathname;
    const lastItem = pageRawTitle.substring(pageRawTitle.lastIndexOf('/') + 1);
    const replace = lastItem.replace(/-/g, ' ');
    const pageTitle = capitalize(replace);

    const {
        breadcrumb: { crumbs }
    } = pageContext;
    const news = data.drupal.nodeQuery.entities[0];
    const { body, entityLabel, fieldDate, fieldPressCategory, fieldMedia } = news;

    const rawnewsdate = moment(fieldDate.date).format('MM/D/YYYY');

    //let newsDate = generateDate(fieldDate.date, "long");

    let alt = '';

    return (
        <Layout>
            <Seo title={pageTitle} />
            <div className='hero__image hero__image--news'>
                <figure>
                    <img src={amNewsHero} alt='ActiveMedia hero'></img>
                </figure>
                <div className='hero__pages'>
                    <div className='hero__news--center'>
                        <h1>News & Events</h1>
                        <p>
                            <span>Stay Up to Date on The Latest News & Events.</span>
                        </p>
                    </div>
                </div>
            </div>
            <CustomBreadcrumb crumbs={crumbs} />
            <div className='main__container press-main__container'>
                <div className='single-blog__container--bg'>
                    <div className='single-blog__container'>
                        <article className='single-blog__box--container'>
                            <div className='single-blog__box--elements'>
                                <div className='single-blog__box--elements--text'>
                                    <div className='single-blog__box--elements--text--header'>
                                        <div className='single-blog__box--elements--text--type single-blog__type--news'>
                                            <FontAwesomeIcon icon={'newspaper'} />
                                        </div>
                                    </div>
                                    <div className='single-blog__box--body'>
                                        <div className='single-blog__box--body--title'>
                                            <h2>{entityLabel}</h2>
                                        </div>
                                        <div className='single-blog__box--body--info'>
                                            <p className='date'>
                                                <FontAwesomeIcon icon={'calendar'} />
                                                {rawnewsdate}
                                            </p>
                                        </div>
                                        <div className='single-blog__box--body--categories'>
                                            {fieldPressCategory.map((category) => (
                                                <div
                                                    className='single-blog__box--body--category'
                                                    key={entityLabel}>
                                                    <span>{category.entity.title}</span>
                                                </div>
                                            ))}
                                        </div>
                                        {/*<Img
                      alt={alt}
                      fluid={fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                    />*/}
                                        <GatsbyImage
                                            alt={alt}
                                            image={
                                                fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                                    ?.gatsbyImageData
                                            }
                                        />
                                        <div className='single-blog__box--body--text'>
                                            <HtmlParser
                                                html={
                                                    body?.processed !== undefined
                                                        ? body.processed
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div className='press-promo-container'>
                <div className='press-promo-container-box'>
                    <div className='press-fafaicon-container'>
                        <div className='press-fafaicon-box'>
                            <FontAwesomeIcon icon='bullhorn' />
                        </div>
                        <h3>Read more</h3>
                    </div>
                    <EventPromo />
                    <div className='press-button'>
                        <Link to='/press'>News & Events</Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
/* } */

Singlenews.propTypes = {
    data: PropTypes.object.isRequired
};

export const query = graphql`
    query ($SinglenewsId: [String]) {
        drupal {
            nodeQuery(
                limit: 10000
                filter: { conditions: { field: "uuid", value: $SinglenewsId } }
            ) {
                count
                entities {
                    entityUuid
                    entityId
                    entityLabel
                    entityBundle
                    ... on Drupal_NodePageNews {
                        nid
                        uuid
                        body {
                            value
                            processed
                        }
                        fieldMedia {
                            entity {
                                uuid
                                url
                            }
                            notStyledImage {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            url
                        }
                        fieldDate {
                            date
                        }
                        fieldNewsType
                        fieldFile {
                            entity {
                                url
                            }
                        }
                        fieldUrl {
                            url {
                                path
                            }
                        }
                        fieldPressCategory {
                            entity {
                                title
                                path {
                                    alias
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Singlenews;
